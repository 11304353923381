<!--学习园地：我的课程：课程作业-->
<template>
  <div id="Task">
    <nav>
      <p>
        <span style="cursor:pointer" @click="ToCourse">我的课程</span>
        / 课程作业
      </p>
    </nav>

    <main class="table">
      <div class="table-box section" style="min-height: 657px">
        <el-table
          size="mini"
          align="center"
          :header-cell-style="{ background: '#fff', color: '#999', fontSize: '14px' }"
          :data="section"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column
            prop="chapterName"
            label="章节名称"
            min-width="300"
            :show-overflow-tooltip="true"
          ></el-table-column>

          <el-table-column prop="submitTime" label="提交时间" width="250"></el-table-column>

          <el-table-column
            prop="state"
            :formatter="stateFormat"
            label="是否点评"
            width="200"
            align="center"
          ></el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row.id)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="param.page"
            :page-size="param.count"
            layout="prev, pager, next, jumper"
            :total="totalCount"
            class="mt-20 pagination-box"
          ></el-pagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import API from '@/api';
import cookies from '@/utils/cookies';

export default {
  name: 'TaskName',
  data() {
    return {
      param: {
        id: '',
        page: 0,
        count: 10,
      },
      totalCount: 0,
      section: [],
    };
  },
  created() {
    this.getTask();
  },
  methods: {
    // 跳转到课程页
    ToCourse() {
      this.$router.push('/Course/MyCourse/My-Course');
    },
    // 分页,当前页数改变时会触发
    handleCurrentChange(page) {
      this.param.page = page - 1;
      this.getTask();
    },
    handleEdit(id) {
      this.$router.push({
        path: '/Course/MyCourse/TaskParticular',
        query: {
          id,
        },
      });
    },
    // 获取作业章节
    getTask() {
      if (cookies.get('token')) {
        this.param.id = this.$route.query.id;
        API.Task({
          id: this.$route.query.id,
          page: this.param.page + 1,
          count: this.param.count,
        })
          .then((res) => {
            // console.log(res)
            this.section = res.message.data.list;
            this.totalCount = res.message.data.totalCount;
          })
          .catch((mgs) => {
            console.log(mgs);
          });
      }
    },
    // 判断是否点评
    stateFormat(row) {
      if (row.state === true) {
        return '是';
      }
      return '否';
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  margin-bottom: 30px;
}
// .table {
//   width: 80vw;
//   overflow-x: hidden;
// }
</style>
